<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="利息计算" fixed>
      <fb-button v-if="!isShare" slot="left" icon="back" @click="backToNative()" size="small" type="primary"> 返回 </fb-button>
      <div slot="right">
        <tool-head type="interest" @clickItem="clickItem">
          <font-size-comp @setFontSize="setFontSize" ref="fontSizeComp" />
        </tool-head>
      </div>
    </fb-header>
    <div id="tools" class="tools-interest" style="font-size: 14px !important">
      <div class="box" style="padding: 0px 20px">
        <div class="box-left">
          金额<span v-show="money" style="margin-left: 5px">{{ yuanToWan(money) }}</span>
        </div>
        <div class="box-right" style="display: flex; align-items: center">
          <van-field
            v-model="money"
            input-align="right"
            placeholder="请输入金额"
            readonly
            clickable
            @touchstart.stop="
              basisPointKeyShow = false
              show = true
              keyboard = 'money'
            "
            style="flex: 1"
            :class="keyboard === 'money' && show ? 'van-number-keyboard-input-select' : ''"
          />
          <van-icon
            name="close"
            @click="
              money = ''
              result = ''
            "
          />
        </div>
      </div>
      <div class="box" style="padding: 0px 20px">
        <div class="box-left">日期</div>
        <div class="box-right" style="flex: 1">
          <div class="box-right-time" @click="showChangeTime('startTimeShow')">
            <span v-show="startTime">{{ startTime }}</span>
            <span style="color: #ccc" v-show="!startTime">选择开始时间</span>
          </div>
          <div>至</div>
          <div class="box-right-time" @click="showChangeTime('endTimeShow')">
            <span v-show="endTime">{{ endTime }}</span>
            <span style="color: #ccc" v-show="!endTime">选择结束时间</span>
          </div>
          <div style="width: 80px; text-align: right">
            <span v-show="day > 0">{{ day }}天</span>
          </div>
        </div>
      </div>
      <div class="box" style="padding: 0px 20px">
        <div class="box-left" style="display: flex; align-items: center; flex: 1">
          <div @click="typeShow = true">
            {{ typeFmt(interestType, options) }}
          </div>
          <van-icon name="arrow-down" />
          <div class="input-box" v-show="interestType !== 'LPR' && interestType !== '银行同期贷款利率'">
            <van-field
              v-model="percentage"
              input-align="right"
              placeholder="请输入"
              readonly
              clickable
              @touchstart.stop="
                basisPointKeyShow = false
                show = true
                keyboard = 'percentage'
              "
              style="flex: 1"
              :class="keyboard === 'percentage' && show ? 'van-number-keyboard-input-select' : ''"
            />
            <van-icon
              name="close"
              @click="
                percentage = ''
                result = ''
              "
            />
          </div>
          <div v-show="interestType !== 'LPR' && interestType !== '银行同期贷款利率'">%</div>
          <div class="input-box" v-show="interestType !== '日利率'">
            <van-field
              v-model="actualDays"
              input-align="right"
              placeholder="请输入"
              readonly
              clickable
              @touchstart.stop="
                basisPointKeyShow = false
                show = true
                keyboard = 'actualDays'
              "
              :class="keyboard === 'actualDays' && show ? 'van-number-keyboard-input-select' : ''"
            />
            <van-icon
              name="close"
              @click="
                actualDays = ''
                result = ''
              "
            />
          </div>
          <div v-show="interestType !== '日利率'">天</div>
        </div>
        <div class="box-right" style="display: flex; align-items: center" v-show="interestType === 'LPR'">
          <div @click="basisPointShow = true">
            {{ typeFmt(value, options2) }}
          </div>
          <van-icon name="arrow-down" />
          <div class="input-box" v-show="value !== '倍率'">
            <van-field
              v-model="basisPoint"
              input-align="right"
              placeholder="请输入"
              readonly
              clickable
              @touchstart.stop="
                keyboard = 'basisPoint'
                show = false
                basisPointKeyShow = true
              "
              :class="keyboard === 'basisPoint' && basisPointKeyShow ? 'van-number-keyboard-input-select' : 'van-number1111'"
            />
            <van-icon
              name="close"
              @click="
                basisPoint = ''
                result = ''
              "
            />
          </div>
        </div>
        <div class="box-right" style="display: flex; align-items: center" v-show="interestType !== 'LPR' || (interestType === 'LPR' && value === '倍率')">
          <div class="input-box">
            <van-field
              v-model="multiple"
              input-align="right"
              placeholder="请输入"
              readonly
              clickable
              @touchstart.stop="
                basisPointKeyShow = false
                show = true
                keyboard = 'multiple'
              "
              :class="keyboard === 'multiple' && show ? 'van-number-keyboard-input-select' : ''"
            />
            <van-icon name="close" @click="multiple = ''" />
          </div>
          <span>倍</span>
        </div>
      </div>
      <div class="box" style="padding: 15px 20px" v-show="interestType !== 'LPR' && interestType !== '银行同期贷款利率'">
        <div class="box-left" style="display: flex; align-item: center">
          <span>无复利</span>
          <van-switch v-model="switchValue" />
        </div>
        <div class="box-right">
          <div class="repayment-during"></div>
        </div>
      </div>
      <div class="box" style="padding: 15px 20px" v-show="(interestType === 'LPR' && startTime && endTime) || (interestType === '银行同期贷款利率' && startTime && endTime)">
        <div class="box-left" style="display: flex; align-item: center">
          <div @click="LPRTimeShow = true" v-show="interestType === 'LPR'">
            {{ typeFmt(LPRTime, options3) }}
          </div>
          <van-icon name="arrow-down" v-show="interestType === 'LPR'" />
        </div>
        <div class="box-right">
          <div @click="LPRTypeShow = true">
            {{ typeFmt(LPRType, options4) }}
          </div>
          <van-icon name="arrow-down" />
        </div>
      </div>
      <!-- <div class="btn">
        <van-button plain type="info" @click="reset">重置</van-button>
        <van-button @click="getResult" style="margin-left:20px" type="info"
          >计算</van-button
        >
      </div> -->
      <div class="interest-result" v-show="result">
        <div>利息：{{ interest }}元</div>
        <div>本金利息：{{ result }}元</div>
        <i @click="copy" style="color: #000000; margin-left: 20px; cursor: pointer" class="iconfont icon-fuzhi"></i>
      </div>
      <div v-show="result" style="color: red; padding: 0 20px">
        {{ overtext }}
      </div>
      <div class="interest-result">
        <span @click="isShowInfo = !isShowInfo" v-show="result">详情</span>
        <span v-show="!result"></span>
        <span>
          <span @click="copyAll" style="margin-right: 10px" v-show="result">复制</span>
          <span @click="getWORD" style="margin-right: 10px" v-show="result && !iphone">下载</span>
          <!-- <span @click="getEXCEL" style="margin-right:10px" v-show="result"
            >导出excel</span
          > -->
          <span @click="share" style="margin-right: 10px">分享</span>
        </span>
      </div>
      <div class="interest-line"></div>
      <!-- 日利率、月利率、年利率显示以下 -->
      <div v-show="isShowInfo && result" v-if="type === 0" style="padding: 0 20px">
        <p :style="`font-size:${fontSize}px !important;color:#CCC`">
          {{ text3 }}
          <!-- <span style="margin-left: 10px">{{ day }}天</span> -->
        </p>
        <p :style="`font-size:${fontSize}px !important`">{{ text1 }}</p>
        <p :style="`font-size:${fontSize}px !important`">总计</p>
        <p :style="`font-size:${fontSize}px !important;color:#CCC`">本息=本金+利息</p>
        <p :style="`font-size:${fontSize}px !important`">{{ text2 }}</p>
        <p :style="`font-size:${fontSize}px !important`">{{ overtext }}</p>
      </div>
      <!--同期LPR或者银行同期贷款显示以下  -->
      <div v-show="isShowInfo && result" v-if="type === 1" style="padding: 0 20px">
        <div :style="`font-size:${fontSize}px !important;color:#CCC`">
          <div v-html="LPR.interestText"></div>
          <!-- <span style="margin-left: 10px">{{ day }}天</span> -->
        </div>
        <!-- 同期LPR、按每月LPR分段计算、默认显示 -->
        <div v-if="LPR.LPRType === '默认'">
          <div class="interest-table-title">
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`" style="flex: 1"></div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`" style="flex: 4">起止时间</div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">天数</div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">类型</div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">利率(%)</div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">利息(元)</div>
          </div>
          <div v-for="(item, i) in LPR.list" :key="i">
            <div class="interest-table-title">
              <div style="flex: 1" :style="`font-size:${fontSize - 1}px !important`">
                {{ i + 1 }}
              </div>
              <div style="flex: 4" :style="`font-size:${fontSize - 1}px !important`">{{ item.startTime }}-{{ item.endTime }}</div>
              <div :style="`font-size:${fontSize - 1}px !important`">
                {{ item.day }}
              </div>
              <div :style="`font-size:${fontSize - 1}px !important`">
                {{ item.type }}
              </div>
              <div :style="`font-size:${fontSize - 1}px !important`">
                {{ item.percentage }}
              </div>
              <div :style="`font-size:${fontSize - 1}px !important`">
                {{ item.interest }}
              </div>
            </div>

            <div style="display: flex; align-items: center; justify-content: flex-start; margin: 5px 10px">
              <p :style="`font-size:${fontSize - 1}px !important`">本金{{ money }}元*{{ item.percentage }}%lpr*{{ item.day }}天/{{ actualDays }}天=利息{{ item.interest }}元</p>
            </div>
          </div>
        </div>
        <!-- 同期LPR、按每月LPR分段计算、等额本金、等额本息显示 -->
        <div v-else>
          <div class="interest-table-title">
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`" style="flex: 1"></div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">月份</div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">还款额</div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">利息</div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">还款本金</div>
            <div :style="`font-size:${fontSize}px !important;font-weight: bold;`">剩余本金</div>
          </div>
          <div class="interest-table-title" v-for="(item, i) in LPR.list" :key="i">
            <div style="flex: 1" :style="`font-size:${fontSize - 1}px !important`">
              {{ i + 1 }}
            </div>
            <div :style="`font-size:${fontSize - 1}px !important`">
              {{ item.time }}
            </div>
            <div :style="`font-size:${fontSize - 1}px !important`">
              {{ item.repayment }}
            </div>
            <div :style="`font-size:${fontSize - 1}px !important`">
              {{ item.interest }}
            </div>
            <div :style="`font-size:${fontSize - 1}px !important`">
              {{ item.money }}
            </div>
            <div :style="`font-size:${fontSize - 1}px !important`">
              {{ item.surplus }}
            </div>
          </div>
        </div>
        <p :style="`font-size:${fontSize}px !important;`">总计</p>
        <p :style="`font-size:${fontSize}px !important;color:#CCC`">本息=本金+利息</p>
        <p :style="`font-size:${fontSize}px !important`">
          {{ LPR.resultText }}
        </p>
      </div>
    </div>
    <van-popup v-model="startTimeShow" position="top">
      <van-datetime-picker v-model="startTimeDate" type="date" title="选择开始时间" :min-date="minDate" :max-date="maxDate" @confirm="startTimeConfirm" @cancel="startTimeShow = false" />
    </van-popup>
    <van-popup v-model="endTimeShow" position="top">
      <van-datetime-picker v-model="endTimeDate" type="date" title="选择结束时间" :min-date="minDate" :max-date="maxDate" @confirm="endTimeConfirm" @cancel="endTimeShow = false" />
    </van-popup>
    <van-number-keyboard :show="show" theme="custom" extra-key="." close-button-text="完成" @blur="show = false" @input="onInput" @delete="onDelete" />
    <van-number-keyboard :show="basisPointKeyShow" theme="custom" :extra-key="['-', '.']" close-button-text="完成" @blur="basisPointKeyShow = false" @input="onInput" @delete="onDelete" />
    <van-popup v-model="typeShow" position="top">
      <cell-radio-group :radio.sync="interestType" :options="options" @select="typeChange" />
    </van-popup>
    <van-popup v-model="basisPointShow" position="top">
      <cell-radio-group :radio.sync="value" :options="options2" @select="basisPointChange" />
    </van-popup>
    <van-popup v-model="LPRTimeShow" position="top">
      <cell-radio-group :radio.sync="LPRTime" :options="options3" @select="LPRTimeChange" />
    </van-popup>
    <van-popup v-model="LPRTypeShow" position="top">
      <cell-radio-group :radio.sync="LPRType" :options="options4" @select="LPRTypeChange" />
    </van-popup>
  </fb-page>
</template>
<script>
import fontSizeComp from '../../components/font-size-comp.vue'
import toolHead from '../../components/toolHead.vue'
import goBackMixin from '@mixins/goBack'
import LPRData from './common/LPRData'
import bankLoanData from './common/bankLoanData.js'
import { qiniuBase } from '~api-config'
import cellRadioGroup from '../../components/cell-radio-group.vue'
import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'
export default {
  name: 'interest',
  mixins: [goBackMixin],
  components: {
    fontSizeComp,
    toolHead,
    cellRadioGroup,
  },
  data() {
    return {
      LPRTimeShow: false,
      LPRTypeShow: false,
      actualDays: 360,
      isShare: false,
      keyboard: '',
      fontSize: 14,
      typeShow: false,
      show: false,
      basisPointKeyShow: false,
      basisPointShow: false,
      basisPointKeyboardShow: false,
      startTimeShow: false,
      endTimeShow: false,
      startTimeDate: new Date(),
      endTimeDate: new Date(),
      maxDate: new Date(2100, 12, 31),
      minDate: new Date(1900, 1, 1),
      startTime: '',
      endTime: '',
      day: 0,
      options: [
        {
          value: '日利率',
          label: '日利率',
        },
        {
          value: '月利率',
          label: '月利率',
        },
        {
          value: '年利率',
          label: '年利率',
        },
        {
          value: 'LPR',
          label: '同期LPR',
        },
        {
          value: '银行同期贷款利率',
          label: '银行同期贷款利率',
        },
      ],
      options2: [
        {
          value: '调整比例',
          label: '调整比例(%)',
        },
        {
          value: '基点数',
          label: '基点数',
        },
        {
          value: '倍率',
          label: '倍率',
        },
      ],
      options3: [
        {
          value: '按每月lpr分段计算',
          label: '按每月lpr分段计算',
        },
        {
          value: '开始时间',
          label: '开始时间',
        },
        {
          value: '结束时间',
          label: '结束时间',
        },
      ],
      options4: [
        {
          value: '默认',
          label: '默认',
        },
        {
          value: '等额本息',
          label: '等额本息',
        },
        {
          value: '等额本金',
          label: '等额本金',
        },
      ],
      LPRType: '默认', // options4的值
      LPRTime: '按每月lpr分段计算', // options3的值
      value: '倍率',
      isShowInfo: false,
      money: '',
      interestType: 'LPR',
      switchValue: true, // 无复利
      percentage: '', // 利率
      multiple: 1, // 倍数
      interest: '', // 利息
      result: '', // 计算结果
      resultList: [], // 参数和结果集合
      text1: '',
      text2: '',
      text3: '',
      type: 0, // 0展示非人民银行  1展示人民银行
      basisPoint: 0, // 基点
      LPR: null,
      LPRList: [],
      overtext: '',
      LPRMontyList: [],
      iphone: false,
    }
  },
  computed: {},
  methods: {
    // 获取时间差
    getDiffYmdBetweenDate(sDate1, sDate2) {
      var fixDate = function (sDate) {
        var aD = sDate.split('-')
        for (var i = 0; i < aD.length; i++) {
          aD[i] = fixZero(parseInt(aD[i]))
        }
        return aD.join('-')
      }
      var fixZero = function (n) {
        return n < 10 ? '0' + n : n
      }
      var fixInt = function (a) {
        for (var i = 0; i < a.length; i++) {
          a[i] = parseInt(a[i])
        }
        return a
      }
      var getMonthDays = function (y, m) {
        var aMonthDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        if (y % 400 === 0 || (y % 4 === 0 && y % 100 !== 0)) {
          aMonthDays[2] = 29
        }
        return aMonthDays[m]
      }
      // var checkDate = function(sDate) {}
      var y = 0
      var m = 0
      var d = 0
      var sTmp
      var aTmp
      sDate1 = fixDate(sDate1)
      sDate2 = fixDate(sDate2)
      if (sDate1 > sDate2) {
        sTmp = sDate2
        sDate2 = sDate1
        sDate1 = sTmp
      }
      var aDate1 = sDate1.split('-')
      aDate1 = fixInt(aDate1)
      var aDate2 = sDate2.split('-')
      aDate2 = fixInt(aDate2)
      y = aDate2[0] - aDate1[0]
      if (sDate2.replace(aDate2[0], '') < sDate1.replace(aDate1[0], '')) {
        y = y - 1
      }
      // 计算月份
      aTmp = [aDate1[0] + y, aDate1[1], fixZero(aDate1[2])]
      while (true) {
        if (aTmp[1] === 12) {
          aTmp[0]++
          aTmp[1] = 1
        } else {
          aTmp[1]++
        }
        if ([aTmp[0], fixZero(aTmp[1]), aTmp[2]].join('-') <= sDate2) {
          m++
        } else {
          break
        }
      }
      // 计算天数
      aTmp = [aDate1[0] + y, aDate1[1] + m, aDate1[2]]
      if (aTmp[1] > 12) {
        aTmp[0]++
        aTmp[1] -= 12
      }
      while (true) {
        if (aTmp[1] > 12) {
          aTmp[0]++
          aTmp[1] -= 12
        }
        if (aTmp[2] >= getMonthDays(aTmp[0], aTmp[1])) {
          aTmp[1]++
          aTmp[2] = 1
        } else {
          aTmp[2]++
        }
        sTmp = [aTmp[0], fixZero(aTmp[1]), fixZero(aTmp[2])].join('-')
        if (sTmp <= sDate2) {
          d++
        } else {
          break
        }
      }
      return { y: y, m: m, d: d }
    },

    showChangeTime(type) {
      this[type] = true
    },
    clickItem(item) {
      this.addHistory()
      const { condition, type } = item
      const conditionObj = JSON.parse(condition)
      if (type === 'interest') {
        const { startTime, endTime, money, interestType, multiple, basisPoint, percentage, switchValue, LPRType, LPRTime, value } = conditionObj
        this.startTime = startTime
        this.endTime = endTime
        this.interestType = interestType
        this.multiple = multiple
        this.basisPoint = basisPoint
        this.percentage = percentage
        this.switchValue = switchValue
        this.LPRType = LPRType
        this.LPRTime = LPRTime
        this.value = value
        this.money = money
        this.getResult()
      }
    },
    addHistory() {
      const type = 'interest'
      const { startTime, endTime, money, interestType, multiple, basisPoint, percentage, switchValue, LPRType, LPRTime, value } = this
      if (!startTime || !endTime || !money) {
        return
      }
      const conditionObj = {
        startTime,
        endTime,
        money,
        interestType,
        multiple,
        basisPoint,
        percentage,
        switchValue,
        LPRType,
        LPRTime,
        value,
      }
      let title = ' ' + money + '元 '
      title += startTime + ' 至 ' + endTime + ' '
      title += ' 利息计算'
      if (this.result) {
        this.$axios
          .post(`${this.$base}/management/utils/history/add`, {
            type,
            condition: JSON.stringify(conditionObj),
            title,
          })
          .then((res) => {
            if (res.data.code === 200) {
              console.log('emit >>> add-history-suc')
              // this.$bus.$emit('add-history-suc', 'interest')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 点击设置字体大小
    setFontSize(size) {
      this.fontSize = size
    },
    // 数字键盘组件输入事件
    onInput(value) {
      let val = this[this.keyboard]
      val += value + ''
      // 如果用户第一位输入的是小数点，则重置输入框内容
      if (val !== '' && val.substr(0, 1) === '.') {
        val = ''
      }
      // 只保留第一个. 清除多余的
      val = val.replace(/\.{2,}/g, '.')
      val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // 如果没有小数点，首位不能为类似于 01、02的数字
      if (val.indexOf('.') < 0 && val !== '') {
        if (val.substr(0, 1) === '0' && val.length === 2) {
          val = val.substr(1, val.length)
        }
      }
      // 倍率输入框，只能输入两位小数
      if (this.keyboard === 'multiple') {
        val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      }
      this[this.keyboard] = val
    },
    // 数字键盘组件删除事件
    onDelete(value) {
      var money = this[this.keyboard].toString()
      this[this.keyboard] = money.substring(0, money.length - 1)
    },
    // 将元转换成万元
    yuanToWan(money) {
      money = parseFloat(money)
      let result = ''
      const wan = parseInt(money / 10000)
      if (wan > 0) {
        result = wan + '万'
      }
      let yuan = money - wan * 10000
      yuan = parseFloat(yuan.toFixed(2))
      if (yuan !== 0) {
        result = result + yuan + '元'
      }
      return result
    },
    // 时间戳转换成时间
    timestampToTime(date) {
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
      return Y + M + D
    },
    // 开始时间选择器点击确定事件
    startTimeConfirm(date) {
      this.startTimeDate = date
      this.startTime = this.timestampToTime(date)
      this.startTimeShow = false
    },
    // 结束时间选择器点击确定事件
    endTimeConfirm(date) {
      this.endTimeDate = date
      this.endTime = this.timestampToTime(date)
      this.endTimeShow = false
    },
    // 格式化
    typeFmt(value, options) {
      let label = ''
      options.map((item) => {
        if (item.value === value) {
          label = item.label
        }
      })
      return label
    },
    // 选择类型
    typeChange() {
      this.typeShow = false
    },
    basisPointChange() {
      this.basisPointShow = false
    },
    LPRTimeChange() {
      this.LPRTimeShow = false
    },
    LPRTypeChange() {
      this.LPRTypeShow = false
    },
    // 点击重置
    reset() {
      this.startTime = ''
      this.endTime = ''
      this.money = ''
      this.interestType = '月利率'
      this.switchValue = true
      this.percentage = ''
      this.multiple = 1
      this.interest = ''
      this.result = ''
      this.actualDays = 365
      this.isShowInfo = false
    },
    // 点击计算按钮，获取结果
    getResult() {
      if (this.money !== '' && this.startTime !== '' && this.endTime !== '') {
        if (!this.money) {
          this.result = ''
          return
          // return this.$notify('金额不能为空')
        }
        if (!this.startTime) {
          this.result = ''
          return
          // return this.$notify('开始时间不能为空')
        }
        if (!this.endTime) {
          this.result = ''
          return
          // return this.$notify('结束时间不能为空')
        }
        if (new Date(this.startTime).getTime() >= new Date(this.endTime).getTime()) {
          this.result = ''
          return
          // return this.$notify('结束时间必须大于开始时间')
        }
        if (this.interestType !== 'LPR' && this.interestType !== '银行同期贷款利率' && !this.percentage) {
          this.result = ''
          return
          // return this.$notify('利率不能为空')
        }
        if (!this.multiple) {
          this.result = ''
          return
          // return this.$notify('倍率不能为空')
        }
        if (isNaN(this.basisPoint) && this.interestType === 'LPR') {
          this.result = ''
          return
          // return this.$notify('请输入正确的调整比例或基点数')
        }
        const dateTime = new Date(this.endTime).getTime() - new Date(this.startTime).getTime()
        const day = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
        this.resultList = []
        this.LPR = null
        this.LPRList = []
        this.LPRMontyList = []
        let result = 0
        this.money = parseFloat(this.money)
        let time = 0
        switch (this.interestType) {
          case '日利率':
            if (this.switchValue) {
              result = this.money + ((this.money * this.percentage) / 100) * this.multiple * day
            } else {
              result = this.money * Math.pow(1 + (this.percentage / 100) * this.multiple, day)
            }
            time = day
            break
          case '月利率':
            var month = (day / this.actualDays) * 12
            if (this.switchValue) {
              result = this.money + ((this.money * this.percentage) / 100) * this.multiple * month
            } else {
              result = this.money * Math.pow(1 + (this.percentage / 100) * this.multiple, month)
            }
            time = month
            break
          case '年利率':
            var year = day / this.actualDays
            if (this.switchValue) {
              result = this.money + ((this.money * this.percentage) / 100) * this.multiple * year
            } else {
              result = this.money * Math.pow(1 + (this.percentage / 100) * this.multiple, year)
            }
            time = year
            break
          case 'LPR':
            if (this.LPRType === '默认') {
              // 利息总额
              var interest = this.getLPR()
              // 本息
              result = interest + this.money
            } else {
              // 利息总额
              var interest3 = this.getLPRByMonth()
              // 本息
              result = interest3 + this.money
            }
            break
          case '银行同期贷款利率':
            if (this.LPRType === '默认') {
              // 利息总额
              var interest2 = this.getBankLoan()
              // 本息
              result = interest2 + this.money
            } else {
              // 利息总额
              var interest4 = this.getBankByMonth()
              // 本息
              result = interest4 + this.money
            }
            break
        }
        this.overtext = ''
        this.result = parseFloat(result.toFixed(2))
        this.interest = parseFloat((result - this.money).toFixed(2))

        if (this.interestType === 'LPR' || this.interestType === '银行同期贷款利率') {
          let interestText = ''
          if (this.interestType === 'LPR') {
            if (this.LPRType === '默认') {
              if (this.value === '调整比例') {
                interestText = '利息=每个月之合(本金*lpr*(1+调整比例%)*每段天数/设置的天数)'
              } else if (this.value === '基点数') {
                interestText = '利息=每个月之合(本金*(lpr+基点数/100*%)*每段天数/设置的天数)'
              } else {
                interestText = '利息=每个月之合(本金*lpr*倍率*每段天数/设置的天数)'
              }
            } else {
              if (this.LPRType === '等额本息') {
                interestText = '每月还款额=贷款金额*月利率*(1+月利率)^还款月数/((1+月利率)^还款月数 - 1)'
                interestText += '<br />每月还款利息=剩余本金*月利率'
                interestText += '<br />每月还款本金=每月还款额-每月还款利息'
              } else if (this.LPRType === '等额本金') {
                interestText = '每月还款额=贷款金额/贷款月数+(贷款金额-累计已归还本金总额)*月利率'
                interestText += '<br />每月还款利息=(贷款金额-累计已归还本金总额)*月利率'
                interestText += '<br />每月还款本金=贷款金额/贷款月数'
              }
            }
          } else {
            if (this.LPRType === '默认') {
              interestText = '利息=每个月之合(本金×利率*倍率*每段天数/设置的天数)'
            } else {
              if (this.LPRType === '等额本息') {
                interestText = '每月还款额=贷款金额*月利率*(1+月利率)^还款月数/((1+月利率)^还款月数 - 1)'
                interestText += '<br />每月还款利息=剩余本金*月利率'
                interestText += '<br />每月还款本金=每月还款额-每月还款利息'
              } else if (this.LPRType === '等额本金') {
                interestText = '每月还款额=贷款金额/贷款月数+(贷款金额-累计已归还本金总额)*月利率'
                interestText += '<br />每月还款利息=(贷款金额-累计已归还本金总额)*月利率'
                interestText += '<br />每月还款本金=贷款金额/贷款月数'
              }
            }
          }
          const resultText = `本息=本金${this.money}元+利息${this.interest}元=本息共${this.result}元`
          this.day = day
          this.LPR = {
            result: this.result,
            interest: this.interest,
            startTime: this.startTime,
            endTime: this.endTime,
            money: this.money,
            type: this.interestType,
            interestText: interestText,
            resultText: resultText,
            basisPoint: this.basisPoint,
            value: this.value,
            multiple: this.multiple,
            day: day,
            actualDays: this.actualDays,
            LPRType: this.LPRType,
            LPRTime: this.LPRTime,
          }
          if (this.LPRType === '默认') {
            this.LPR = {
              ...this.LPR,
              list: this.LPRList,
            }
          } else {
            this.LPR = {
              ...this.LPR,
              list: this.LPRMontyList,
            }
          }
          this.type = 1
        } else {
          this.type = 0
          this.day = day
          // 存下这次计算的参数和结果，方便导出
          const obj = {
            money: this.money,
            startTime: this.startTime,
            endTime: this.endTime,
            type: this.interestType,
            percentage: this.percentage / 100,
            multiple: this.multiple,
            switchValue: this.switchValue ? '无复利' : '有复利',
            interest: this.interest,
            result: this.result,
            time: time,
            day: day,
            actualDays: this.actualDays,
            over: 0,
          }
          var LPRInterest = parseFloat((this.getLPR() * 4).toFixed(2))
          var over = parseFloat((this.interest - LPRInterest).toFixed(2))
          if (over > 0) {
            obj.over = over
            obj.LPRInterest = LPRInterest
            this.overtext = `根据最高人民法院关于审理民间借贷案件适用法律若干问题的规定，高于同期lpr利息4倍${LPRInterest}元，超出${over}元。`
          }
          this.resultList.push(obj)
          this.text2 = `本息=本金${this.resultList[0].money}元+利息${this.resultList[0].interest}元=本息共${this.resultList[this.resultList.length - 1].result}元`
          let text2 = ''
          let text3 = ''
          switch (this.resultList[0].type) {
            case '日利率':
              text2 = '天数'
              text3 = '实际天数'
              break
            case '月利率':
              text2 = '月数'
              text3 = `实际天数/${this.actualDays}*12`
              break
            case '年利率':
              text2 = '年数'
              text3 = `实际天数/${this.actualDays}`
              break
          }
          for (var i = 0; i < this.resultList.length; i++) {
            if (this.resultList[i].switchValue === '无复利') {
              let str = ''
              switch (this.resultList[0].type) {
                case '日利率':
                  str = `${parseFloat(this.resultList[i].time.toFixed(4))}`
                  break
                case '月利率':
                  str = `(${this.day}/${this.actualDays}*12)`
                  break
                case '年利率':
                  str = `(${this.day}/${this.actualDays})`
                  break
              }
              this.text1 = `利息=本金${this.resultList[i].money}元*${Math.round(this.resultList[i].percentage * 100000000000000) / 100000000000000}*${this.resultList[i].multiple}*${str}=利息${
                this.resultList[i].interest
              }元`
              this.text3 = `利息=本金×${this.resultList[i].type}*倍率×贷款${text2}(${text3})`
            } else {
              this.text1 = `复利利息=本金${this.resultList[i].money}元*(1+${this.resultList[i].percentage}*${this.resultList[i].multiple})^${parseFloat(this.resultList[i].time.toFixed(4))}天 - 本金${
                this.resultList[i].money
              }元=利息${this.resultList[i].interest}元`
              this.text3 = `复利利息=本金*(1+${this.resultList[i].type}*倍率)^贷款${text2}-本金`
            }
          }
        }
      }
    },

    /**
     * 计算LPR利息
     * @return 利息总和
     */
    getLPR() {
      const { y, m, d } = this.getDiffYmdBetweenDate(this.startTime, this.endTime)
      // 计算总时长
      var index = 0
      if (y > 5) {
        // 大于5年
        index = 5
      } else if (y === 5) {
        if (m > 0 || d > 0) {
          // 大于5年
          index = 5
        } else {
          // 5年
          index = 4
        }
      } else if (y < 5 && y > 3) {
        // 3-5年
        index = 3
      } else if (y === 3) {
        if (m > 0 || d > 0) {
          // 3-5年
          index = 3
        } else {
          // 1-3年
          index = 2
        }
      } else if (y < 3 && y > 1) {
        // 1-3年
        index = 2
      } else if (y === 1) {
        if (m > 0 || d > 0) {
          // 1-3年
          index = 2
        } else {
          // 6个月-1年
          index = 1
        }
      } else if (y < 1) {
        if (m > 6) {
          // 6个月-1年
          index = 1
        } else if (m === 6) {
          if (d > 0) {
            // 6个月-1年
            index = 1
          } else {
            // 0-6个月
            index = 0
          }
        } else {
          // 0-6个月
          index = 0
        }
      }
      let type = ''
      // 1.获取到开始时间和结束时间的索引
      const startTime = new Date(this.startTime).getTime()
      const endTime = new Date(this.endTime).getTime()
      let startTimeIndex = ''
      let endTimeIndex = ''
      for (let i = 0; i < LPRData.length; i++) {
        if (new Date(LPRData[i].startTime).getTime() > startTime && startTimeIndex === '') {
          if (i === 0) {
            startTimeIndex = 0
          } else {
            startTimeIndex = i - 1
          }
        }
        if (i === LPRData.length - 1 && endTimeIndex === '') {
          endTimeIndex = LPRData.length - 1
        } else {
          if (new Date(LPRData[i].endTime).getTime() >= endTime && endTimeIndex === '') {
            if (i === 0) {
              endTimeIndex = 0
            } else {
              endTimeIndex = i
            }
          }
        }
        if (i === LPRData.length - 1 && startTimeIndex === '') {
          startTimeIndex = LPRData.length - 1
        }
      }
      // 2.利用第一步获取的索引计算结果
      let result = 0

      for (let j = startTimeIndex; j < endTimeIndex + 1; j++) {
        let tempStartTime = 0
        let tempEndTime = 0
        if (startTimeIndex === endTimeIndex) {
          // 如果恰好在一期
          tempStartTime = this.startTime
          tempEndTime = this.endTime
        } else {
          if (j === startTimeIndex) {
            tempStartTime = this.startTime
            tempEndTime = LPRData[j].endTime
          } else if (j === endTimeIndex) {
            tempStartTime = LPRData[j].startTime
            tempEndTime = this.endTime
          } else if (j < endTimeIndex && j > startTimeIndex) {
            tempStartTime = LPRData[j].startTime
            tempEndTime = LPRData[j].endTime
          }
        }
        // console.log(tempStartTime, tempEndTime)
        const dateTime = new Date(tempEndTime).getTime() - new Date(tempStartTime).getTime()
        const day = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
        var year = day / this.actualDays
        let tempResult = 0
        if (this.basisPoint === '') {
          this.basisPoint = 0
        }
        // 是否按每月lpr分段计算
        let percentage = LPRData[j].values[index]
        if (this.LPRTime === '开始时间') {
          percentage = LPRData[startTimeIndex].values[index]
        } else if (this.LPRTime === '结束时间') {
          percentage = LPRData[endTimeIndex].values[index]
        }
        if (this.value === '调整比例') {
          tempResult = ((this.money * percentage) / 100) * (1 + parseFloat(this.basisPoint) / 100) * year
        } else if (this.value === '基点数') {
          percentage = parseFloat((percentage + parseFloat(this.basisPoint) / 100).toFixed(2))
          tempResult = ((this.money * percentage) / 100) * year
        } else {
          const mulPercentage = parseFloat((percentage * parseFloat(this.multiple)).toFixed(2))
          tempResult = ((this.money * mulPercentage) / 100) * year
        }
        tempResult = parseFloat(tempResult.toFixed(2))
        result += tempResult
        type = LPRData[j].types[index]
        const obj = {
          startTime: tempStartTime,
          endTime: tempEndTime,
          percentage: percentage,
          interest: tempResult,
          type: type,
          day: day,
        }
        this.LPRList.push(obj)
      }

      return result
    },

    /**
     * 计算银行同期贷款利率利息
     * @index bankLoanData中values的索引
     * @return 利息总和
     */
    getBankLoan() {
      const { y, m, d } = this.getDiffYmdBetweenDate(this.startTime, this.endTime)
      // 计算总时长
      var index = 0
      if (y > 5) {
        // 5年
        index = 4
      } else if (y === 5) {
        if (m > 0 || d > 0) {
          // 5年
          index = 4
        } else {
          // 3-5年
          index = 3
        }
      } else if (y < 5 && y > 3) {
        // 3-5年
        index = 3
      } else if (y === 3) {
        if (m > 0 || d > 0) {
          // 3-5年
          index = 3
        } else {
          // 1-3年
          index = 2
        }
      } else if (y < 3 && y > 1) {
        // 1-3年
        index = 2
      } else if (y === 1) {
        if (m > 0 || d > 0) {
          // 1-3年
          index = 2
        } else {
          // 6个月-1年
          index = 1
        }
      } else if (y < 1) {
        if (m > 6) {
          // 6个月-1年
          index = 1
        } else if (m === 6) {
          if (d > 0) {
            // 6个月-1年
            index = 1
          } else {
            // 0-6个月
            index = 0
          }
        } else {
          // 0-6个月
          index = 0
        }
      }
      let type = ''
      // 1.获取到开始时间和结束时间的索引
      const startTime = new Date(this.startTime).getTime()
      const endTime = new Date(this.endTime).getTime()
      let startTimeIndex = ''
      let endTimeIndex = ''
      for (let i = 0; i < bankLoanData.length; i++) {
        if (new Date(bankLoanData[i].startTime).getTime() > startTime && startTimeIndex === '') {
          if (i === 0) {
            startTimeIndex = 0
          } else {
            startTimeIndex = i - 1
          }
        }
        if (i === bankLoanData.length - 1 && endTimeIndex === '') {
          endTimeIndex = bankLoanData.length - 1
        } else {
          if (new Date(bankLoanData[i].endTime).getTime() >= endTime && endTimeIndex === '') {
            if (i === 0) {
              endTimeIndex = 0
            } else {
              endTimeIndex = i
            }
          }
        }
        if (i === bankLoanData.length - 1 && startTimeIndex === '') {
          startTimeIndex = bankLoanData.length - 1
        }
      }
      // 2.利用第一步获取的索引计算结果
      let result = 0
      for (let j = startTimeIndex; j < endTimeIndex + 1; j++) {
        let tempStartTime = 0
        let tempEndTime = 0
        if (startTimeIndex === endTimeIndex) {
          // 如果恰好在一期
          tempStartTime = this.startTime
          tempEndTime = this.endTime
        } else {
          if (j === startTimeIndex) {
            tempStartTime = this.startTime
            tempEndTime = bankLoanData[j].endTime
          } else if (j === endTimeIndex) {
            tempStartTime = bankLoanData[j].startTime
            tempEndTime = this.endTime
          } else if (j < endTimeIndex && j > startTimeIndex) {
            tempStartTime = bankLoanData[j].startTime
            tempEndTime = bankLoanData[j].endTime
          }
        }
        const dateTime = new Date(tempEndTime).getTime() - new Date(tempStartTime).getTime()
        const day = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
        var year = day / this.actualDays
        let tempResult = 0
        const percentage = bankLoanData[j].values[index]
        tempResult = ((this.money * percentage * this.multiple) / 100) * year
        tempResult = parseFloat(tempResult.toFixed(2))
        result += tempResult
        type = bankLoanData[j].types[index]
        const obj = {
          startTime: tempStartTime,
          endTime: tempEndTime,
          percentage: percentage,
          interest: tempResult,
          type: type,
          day: day,
        }
        this.LPRList.push(obj)
      }
      return result
    },

    /**
     * LPR等额本息
     */
    getLPRByMonth() {
      const startTimeList = this.startTime.split('-')
      const endTimeList = this.endTime.split('-')
      let y = parseInt(endTimeList[0] - startTimeList[0])
      let m = parseInt(endTimeList[1] - startTimeList[1])
      if (m < 0) {
        y = y - 1
        m = 12 + m
      }
      const d = 0
      // 总月数
      var allMonth = y * 12 + m
      if (allMonth === 0) {
        allMonth += 1
      }
      // 计算总时长
      var index = 0
      if (y > 5) {
        // 大于5年
        index = 5
      } else if (y === 5) {
        if (m > 0 || d > 0) {
          // 大于5年
          index = 5
        } else {
          // 5年
          index = 4
        }
      } else if (y < 5 && y > 3) {
        // 3-5年
        index = 3
      } else if (y === 3) {
        if (m > 0 || d > 0) {
          // 3-5年
          index = 3
        } else {
          // 1-3年
          index = 2
        }
      } else if (y < 3 && y > 1) {
        // 1-3年
        index = 2
      } else if (y === 1) {
        if (m > 0 || d > 0) {
          // 1-3年
          index = 2
        } else {
          // 6个月-1年
          index = 1
        }
      } else if (y < 1) {
        if (m > 6) {
          // 6个月-1年
          index = 1
        } else if (m === 6) {
          if (d > 0) {
            // 6个月-1年
            index = 1
          } else {
            // 0-6个月
            index = 0
          }
        } else {
          // 0-6个月
          index = 0
        }
      }
      // 利息总额
      let result = 0
      // 已还款总额
      let repayment = 0
      for (var t = 0; t < allMonth; t++) {
        // 月份
        let year = parseInt(startTimeList[0])
        let month = parseInt(startTimeList[1]) + t
        if (month > 12) {
          year = year + parseInt(month / 12)
          month = month % 12
          if (month === 0) {
            month = 12
          }
        }
        month = month >= 10 ? month : '0' + month
        // 月份
        var tempTime = year + '-' + month
        var time = tempTime
        // 利率
        let percentage = 0
        if (this.LPRTime === '开始时间') {
          time = this.startTime.substr(0, 7)
        } else if (this.LPRTime === '结束时间') {
          time = this.endTime.substr(0, 7)
        }
        for (let i = 0; i < LPRData.length; i++) {
          const ST = LPRData[i].startTime.substr(0, 7)
          const ET = LPRData[i].endTime.substr(0, 7)
          if (new Date(time).getTime() >= new Date(ST).getTime() && new Date(time).getTime() <= new Date(ET).getTime()) {
            // 在当前区间中
            percentage = LPRData[i].values[index]
          }
        }
        if (new Date(time).getTime() >= new Date(LPRData[LPRData.length - 1].startTime.substr(0, 7)).getTime()) {
          percentage = LPRData[LPRData.length - 1].values[index]
        }
        if (new Date(time).getTime() < new Date(LPRData[0].startTime.substr(0, 7)).getTime()) {
          percentage = LPRData[0].values[index]
        }
        percentage = (percentage + this.basisPoint) / 12
        // percentage = parseInt(percentage.toFixed(2))
        if (this.LPRType === '等额本息') {
          // 每月还款额
          let tempRepayment = (((this.money * percentage) / 100) * Math.pow(1 + percentage / 100, allMonth)) / (Math.pow(1 + percentage / 100, allMonth) - 1)
          tempRepayment = parseFloat(tempRepayment.toFixed(2))
          // 每月还款利息
          let tempInterest = ((this.money - repayment) * percentage) / 100
          tempInterest = parseFloat(tempInterest.toFixed(2))
          // 每月还款本金
          let tempMoney = tempRepayment - tempInterest
          tempMoney = parseFloat(tempMoney.toFixed(2))
          repayment += tempMoney
          // 剩余本金
          let surplus = this.money - repayment
          surplus = parseFloat(surplus.toFixed(2))
          const obj = {
            time: tempTime,
            repayment: tempRepayment,
            interest: tempInterest,
            money: tempMoney,
            surplus: surplus,
          }
          this.LPRMontyList.push(obj)
          result += tempInterest
        } else if (this.LPRType === '等额本金') {
          // 每月还款本金
          let tempMoney = this.money / allMonth
          tempMoney = parseFloat(tempMoney.toFixed(2))
          // 每月还款利息
          let tempInterest = (this.money - repayment) * (percentage / 100)
          tempInterest = parseFloat(tempInterest.toFixed(2))
          // 每月还款额
          let tempRepayment = this.money / allMonth + (this.money - repayment) * (percentage / 100)
          tempRepayment = parseFloat(tempRepayment.toFixed(2))
          repayment += tempMoney
          // 剩余本金
          let surplus = this.money - repayment
          surplus = parseFloat(surplus.toFixed(2))
          const obj = {
            time: tempTime,
            repayment: tempRepayment,
            interest: tempInterest,
            money: tempMoney,
            surplus: surplus,
          }
          this.LPRMontyList.push(obj)
          result += tempInterest
        }
      }
      return result
    },

    /**
     * 银行同期等额本息
     */
    getBankByMonth() {
      const startTimeList = this.startTime.split('-')
      const endTimeList = this.endTime.split('-')
      let y = parseInt(endTimeList[0] - startTimeList[0])
      let m = parseInt(endTimeList[1] - startTimeList[1])
      if (m < 0) {
        y = y - 1
        m = 12 + m
      }
      const d = 0
      // 总月数
      var allMonth = y * 12 + m
      if (allMonth === 0) {
        allMonth += 1
      }
      // 计算总时长
      var index = 0
      if (y > 5) {
        // 大于5年
        index = 5
      } else if (y === 5) {
        if (m > 0 || d > 0) {
          // 大于5年
          index = 5
        } else {
          // 5年
          index = 4
        }
      } else if (y < 5 && y > 3) {
        // 3-5年
        index = 3
      } else if (y === 3) {
        if (m > 0 || d > 0) {
          // 3-5年
          index = 3
        } else {
          // 1-3年
          index = 2
        }
      } else if (y < 3 && y > 1) {
        // 1-3年
        index = 2
      } else if (y === 1) {
        if (m > 0 || d > 0) {
          // 1-3年
          index = 2
        } else {
          // 6个月-1年
          index = 1
        }
      } else if (y < 1) {
        if (m > 6) {
          // 6个月-1年
          index = 1
        } else if (m === 6) {
          if (d > 0) {
            // 6个月-1年
            index = 1
          } else {
            // 0-6个月
            index = 0
          }
        } else {
          // 0-6个月
          index = 0
        }
      }
      // 利息总额
      let result = 0
      // 已还款总额
      let repayment = 0
      for (var t = 0; t < allMonth; t++) {
        // 月份
        let year = parseInt(startTimeList[0])
        let month = parseInt(startTimeList[1]) + t
        if (month > 12) {
          year = year + parseInt(month / 12)
          month = month % 12
          if (month === 0) {
            month = 12
          }
        }
        month = month >= 10 ? month : '0' + month
        // 月份
        var tempTime = year + '-' + month
        var time = tempTime
        // 利率
        let percentage = 0
        for (let i = 0; i < bankLoanData.length; i++) {
          const ST = bankLoanData[i].startTime.substr(0, 7)
          const ET = bankLoanData[i].endTime.substr(0, 7)
          if (new Date(time).getTime() >= new Date(ST).getTime() && new Date(time).getTime() <= new Date(ET).getTime()) {
            // 在当前区间中
            percentage = bankLoanData[i].values[index]
          }
        }
        if (new Date(time).getTime() >= new Date(bankLoanData[bankLoanData.length - 1].startTime.substr(0, 7)).getTime()) {
          percentage = bankLoanData[bankLoanData.length - 1].values[index]
        }
        if (new Date(time).getTime() < new Date(bankLoanData[0].startTime.substr(0, 7)).getTime()) {
          percentage = bankLoanData[0].values[index]
        }
        percentage = (percentage * this.multiple) / 12
        // percentage = parseInt(percentage.toFixed(2))
        if (this.LPRType === '等额本息') {
          // 每月还款额
          let tempRepayment = (((this.money * percentage) / 100) * Math.pow(1 + percentage / 100, allMonth)) / (Math.pow(1 + percentage / 100, allMonth) - 1)
          tempRepayment = parseFloat(tempRepayment.toFixed(2))
          // 每月还款利息
          let tempInterest = ((this.money - repayment) * percentage) / 100
          tempInterest = parseFloat(tempInterest.toFixed(2))
          // 每月还款本金
          let tempMoney = tempRepayment - tempInterest
          tempMoney = parseFloat(tempMoney.toFixed(2))
          repayment += tempMoney
          // 剩余本金
          let surplus = this.money - repayment
          surplus = parseFloat(surplus.toFixed(2))
          const obj = {
            time: tempTime,
            repayment: tempRepayment,
            interest: tempInterest,
            money: tempMoney,
            surplus: surplus,
          }
          this.LPRMontyList.push(obj)
          result += tempInterest
        } else if (this.LPRType === '等额本金') {
          // 每月还款本金
          let tempMoney = this.money / allMonth
          tempMoney = parseFloat(tempMoney.toFixed(2))
          // 每月还款利息
          let tempInterest = (this.money - repayment) * (percentage / 100)
          tempInterest = parseFloat(tempInterest.toFixed(2))
          // 每月还款额
          let tempRepayment = this.money / allMonth + (this.money - repayment) * (percentage / 100)
          tempRepayment = parseFloat(tempRepayment.toFixed(2))
          repayment += tempMoney
          // 剩余本金
          let surplus = this.money - repayment
          surplus = parseFloat(surplus.toFixed(2))
          const obj = {
            time: tempTime,
            repayment: tempRepayment,
            interest: tempInterest,
            money: tempMoney,
            surplus: surplus,
          }
          this.LPRMontyList.push(obj)
          result += tempInterest
        }
      }
      return result
    },

    // 点击导出word
    getWORD() {
      if (this.type === 1) {
        if (this.LPRType === '默认') {
          let text = `\t律呗利息计算\t\n金额: ${this.yuanToWan(this.LPR.money)}   ${this.LPR.type}\n日期：${this.LPR.startTime}——${this.LPR.endTime}\n利息：${this.LPR.interest}元   本息：${
            this.LPR.result
          }元\n\n详情\n${this.LPR.interestText}      ${this.day}天`
          let temp = '\n         开始时间            结束时间              天数        类型        利率       利息'
          this.LPR.list.map((item, index) => {
            temp += `\n${index + 1}      ${item.startTime}      ${item.endTime}      ${item.day}      ${item.type}      ${item.percentage}      ${item.interest}\n本金${this.money}元*${
              item.percentage
            }%lpr*${item.day}天/${this.actualDays}天=利息${item.interest}元`
          })
          text += temp
          text = text + `\n本息=本金+利息\n本息=${this.LPR.money}+${this.LPR.interest}=${this.LPR.result}`
          this.getTxt(text)
        } else {
          const key = '<br />'
          const interestText = this.LPR.interestText.replace(new RegExp(key, 'g'), '\n')
          let text = `\t律呗利息计算\t\n金额: ${this.yuanToWan(this.LPR.money)}   ${this.LPR.type}\n日期：${this.LPR.startTime}——${this.LPR.endTime}\n利息：${this.LPR.interest}元   本息：${
            this.LPR.result
          }元\n\n详情\n${interestText}      ${this.day}天`
          let temp = '\n         月份            还款额              利息        还款本金        剩余本金'
          this.LPR.list.map((item, index) => {
            temp += `\n${index + 1}      ${item.time}      ${item.repayment}           ${item.interest}        ${item.money}        ${item.surplus}`
          })
          text += temp
          text = text + `\n本息=本金+利息\n本息=本金${this.LPR.money}元+利息${this.LPR.interest}元=本息共${this.LPR.result}元`
          this.getTxt(text)
        }
      } else {
        let text1 = ''
        let text2 = ''
        switch (this.resultList[0].type) {
          case '日利率':
            text1 = '天'
            text2 = '天数'
            break
          case '月利率':
            text1 = '个月'
            text2 = '月数'
            break
          case '年利率':
            text1 = '年'
            text2 = '年数'
            break
        }
        let tempText1 = ''
        if (this.resultList[0].switchValue === '无复利') {
          tempText1 = `\n利息${this.resultList[0].interest}=${this.resultList[0].money}(本金)*${this.resultList[0].percentage}(${this.resultList[0].type})*${
            this.resultList[0].multiple
          }(倍率)*${parseFloat(this.resultList[0].time.toFixed(4))}(${text2})      ${this.day}天`
        } else {
          tempText1 = `\n复利利息${this.resultList[0].interest}=${this.resultList[0].money}(本金)*(1+${this.resultList[0].percentage}(${this.resultList[0].type})*${
            this.resultList[0].multiple
          }(倍率))^${parseFloat(this.resultList[0].time.toFixed(4))}(${text2}) - ${this.resultList[0].money}(本金)      ${this.day}天`
        }

        let text = `\t律呗利息计算\t\n金额: ${this.yuanToWan(this.resultList[0].money)}   ${this.resultList[0].type}${this.resultList[0].percentage}   ${
          this.resultList[0].multiple
        }倍率   ${parseFloat(this.resultList[0].time.toFixed(4))}${text1}   ${this.resultList[0].switchValue}\n日期：${this.resultList[0].startTime}——${this.resultList[0].endTime}\n利息：${
          this.resultList[0].interest
        }元   本息：${this.resultList[0].result}元\n\n详情${tempText1}\n本息${this.resultList[0].result}=${this.resultList[0].money}(本金）+ ${this.resultList[0].interest}(利息)`
        if (this.resultList[0].over > 0) {
          text += '\n' + this.overtext
        }
        this.getTxt(text)
      }
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    // 生成txt
    getTxt(text) {
      // const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      var blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
      console.log(blob)
      const file = new File([blob], '利息计算.txt', {
        type: 'text/plain;charset=utf-8',
      })
      console.log(file)
      const qiniu = new this.$qiniu()
      qiniu.UploadFile(
        'DEFAULT',
        file,
        {
          next: (file) => {},
          complete: () => {},
          error: () => {
            this.$toast('上传失败，上传终止')
          },
        },
        (fileopt, key, file) => {
          // 拼接完整的图片的地址
          // key = key.replace(/\/\/+/g, '/')
          // console.log(key)
          const url = window.location.protocol + qiniuBase + '/' + key
          // const url = 'https:' + qiniuBase + '/' + key
          const name = url.substring(url.lastIndexOf('/') + 1)
          // const pathUrl = url.substring(0, url.lastIndexOf('/')) // 截取图片的路径
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // eslint-disable-next-line
            // AND2JS.showFilePreview(url)
            window.showFilePreview.postMessage(
              JSON.stringify({
                filePath: url,
                fileName: name,
              })
            )
          } else if (ua === 'fb-flutter-Webview') {
            // flutter环境
            // eslint-disable-next-line
            try {
              // 之前写的方法
              // window.fbDownloadFile.postMessage(url)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: url,
                  // filePath: 'https://oss.farbun.com/legal/precedent/797780386196361216/1700535266048.docx',
                  fileName: name,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.i) {
            this.iphone = true
            // ios环境
            // eslint-disable-next-line
            // window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
          } else {
            // window.location = url
            console.log('下载地址:', url)
            console.log('下载文件名:', name)
          }
        }
      )
    },

    // 点击导出excel
    getEXCEL() {
      // 生成csv
      this.getCSV()
    },
    // 生成csv
    getCSV() {
      // 解决中文乱码问题
      let str = '\ufeff'
      if (this.type === 1) {
        if (this.LPRType === '默认') {
          str = `总金额,${this.LPR.money},开始时间,${this.LPR.startTime},结束时间,${this.LPR.endTime},利息总额,${this.LPR.interest},本息总额,${this.LPR.result}\n`
          str += '序号,开始时间,结束时间,天数,类型,利率,结果\n'
          for (let i = 0; i < this.LPR.list.length; i++) {
            str += `${i + 1},`
            str += this.LPR.list[i].startTime
            str += ','
            str += this.LPR.list[i].endTime
            str += ','
            str += this.LPR.list[i].day
            str += ','
            str += this.LPR.list[i].type
            str += ','
            str += this.LPR.list[i].percentage
            str += ','
            str += this.LPR.list[i].interest
            str += ','
            str += '\n'
          }
        } else {
          str = `总金额,${this.LPR.money},开始时间,${this.LPR.startTime},结束时间,${this.LPR.endTime},利息总额,${this.LPR.interest},本息总额,${this.LPR.result}\n`
          str += '序号,月份,还款额,利息,还款本金,剩余本金\n'
          for (let i = 0; i < this.LPR.list.length; i++) {
            console.log(this.LPR.list[i].time)
            str += `${i + 1},`
            str += this.LPR.list[i].time
            str += ','
            str += this.LPR.list[i].repayment
            str += ','
            str += this.LPR.list[i].interest
            str += ','
            str += this.LPR.list[i].money
            str += ','
            str += this.LPR.list[i].surplus
            str += ','
            str += '\n'
          }
        }
      } else {
        // 列标题，逗号隔开，每一个逗号就是隔开一个单元格
        str += `总金额,${this.resultList[0].money},开始时间,${this.resultList[0].startTime},结束时间,${this.resultList[0].endTime},利息总额,${this.resultList[0].interest},本息总额,${this.resultList[0].result}\n`
        str += '序号,开始时间,结束时间,类型,利率,结果\n'
        // 增加\t为了不让表格显示科学计数法或者其他格式
        for (let i = 0; i < this.resultList.length; i++) {
          str += `${i + 1},`
          str += `${this.resultList[i].startTime},`
          str += `${this.resultList[i].endTime},`
          str += `${this.resultList[i].type},`
          str += `${this.resultList[i].percentage},`
          str += `${this.resultList[i].interest},`
          str += '\n'
        }
      }
      const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      var blob = new Blob([str], { type: 'text/csv;charset=utf-8' })
      console.log(blob)
      const file = new File([blob], '计算结果.csv', {
        type: 'text/csv;charset=utf-8',
      })
      console.log(file)
      const qiniu = new this.$qiniu()
      qiniu.UploadFile(
        'DEFAULT',
        file,
        {
          next: (file) => {},
          complete: () => {},
          error: () => {
            this.$toast('上传失败，上传终止')
          },
        },
        (fileopt, key, file) => {
          // 拼接完整的图片的地址
          const url = window.location.protocol + qiniuBase + '/' + key
          console.log(url, '====>>> 接口返参21', '导出Excel没使用')
          // console.log(url)
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // eslint-disable-next-line
            AND2JS.showFilePreview(url)
          } else if (ua === 'fb-flutter-Webview') {
            // flutter环境
            // eslint-disable-next-line
            try {
              // 之前写的方法
              // window.fbDownloadFile.postMessage(url)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: url,
                  fileName: time,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.i) {
            // eslint-disable-next-line
            window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
          } else {
            window.location = url
          }
        }
      )
    },

    // 复制全部内容
    async copyAll() {
      let str = ''
      if (this.type === 1) {
        if (this.LPRType === '默认') {
          let text = `${this.LPR.interestText}      ${this.day}天`
          let temp = '\n         开始时间            结束时间              天数        类型        利率       利息'
          this.LPR.list.map((item, index) => {
            temp += `\n${index + 1}      ${item.startTime}      ${item.endTime}      ${item.day}      ${item.type}      ${item.percentage}      ${item.interest}\n
            本金${this.money}元*${item.percentage}%lpr*${item.day}天/${this.actualDays}天=利息${item.interest}元`
          })
          text += temp
          text = text + `\n本息=本金+利息\n本息=本金${this.LPR.money}元+ 利息${this.LPR.interest}元=本息共${this.LPR.result}元`
          str += text
        } else {
          const key = '<br />'
          let text = this.LPR.interestText.replace(new RegExp(key, 'g'), '\n')
          let temp = '\n         月份            还款额              利息        还款本金        剩余本金'
          this.LPR.list.map((item, index) => {
            temp += `\n${index + 1}      ${item.time}      ${item.repayment}           ${item.interest}        ${item.money}        ${item.surplus}`
          })
          text += temp
          text = text + `\n本息=本金+利息\n本息=本金${this.LPR.money}元+ 利息${this.LPR.interest}元=本息共${this.LPR.result}元`
          str += text
        }
      } else {
        let tempText1 = ''
        if (this.resultList[0].switchValue === '无复利') {
          tempText1 = `\n利息=本金${this.resultList[0].money}元*${this.resultList[0].percentage}*${this.resultList[0].multiple}*${parseFloat(this.resultList[0].time.toFixed(4))}=${
            this.resultList[0].interest
          }元`
        } else {
          tempText1 = `\n复利利息${this.resultList[0].interest}元=本金${this.resultList[0].money}元*(1+${this.resultList[0].percentage}*${this.resultList[0].multiple})^${parseFloat(
            this.resultList[0].time.toFixed(4)
          )}天 - 本金${this.resultList[0].money}元`
        }
        const text = `${this.text3}    ${this.day}天${tempText1}\n本息=本金+利息\n本息=本金${this.resultList[0].money}元+利息${this.resultList[0].interest}元=本息共${this.resultList[0].result}元`
        str += text
        if (this.resultList[0].over > 0) {
          str += '\n' + this.overtext
        }
      }
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = str // 这里表示想要复制的内容
      let url = window.location.href
      const res = await longUrl2ShortUrl(url)
      if (res.status === 200) {
        url = res.data
        str += '\n' + url
      }
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      this.addHistory()
    },

    // 点击复制图标
    copy() {
      let text = `利息：${this.interest}元，本金利息：${this.result}元`
      text += '\n' + this.overtext
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      this.addHistory()
    },

    // 点击分享
    async share() {
      let text = window.location.href
      if (this.result) {
        if (this.type === 1) {
          const obj = {
            startTime: this.LPR.startTime,
            endTime: this.LPR.endTime,
            money: this.LPR.money,
            interestType: this.LPR.type,
            basisPoint: this.LPR.basisPoint,
            value: this.LPR.value,
            multiple: this.LPR.multiple,
            actualDays: this.LPR.actualDays,
            LPRTime: this.LPRTime,
            LPRType: this.LPRType,
          }
          text += `?data=${JSON.stringify(obj)}`
        } else {
          const obj = {
            startTime: this.resultList[0].startTime,
            endTime: this.resultList[0].endTime,
            money: this.resultList[0].money,
            interestType: this.resultList[0].type,
            multiple: this.resultList[0].multiple,
            percentage: this.resultList[0].percentage * 100,
            switchValue: this.resultList[0].switchValue === '无复利',
            actualDays: this.resultList[0].actualDays,
          }
          text += `?data=${JSON.stringify(obj)}`
        }
      }
      const res = await longUrl2ShortUrl(text)
      if (res.status === 200) {
        text = res.data
      }
      let title = ''
      if (this.result) {
        title = `本息总额${this.result}元`
      } else {
        title = '利息计算'
      }
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(title, '律呗利息计算工具', window.encodeURI(text))
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: title,
            url: window.encodeURI(text),
          })
        )
      } else {
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已复制本网页地址，请粘贴到QQ或微信中',
        })
      }
      this.addHistory()
    },
  },
  created() {
    if (this.$route.query.data) {
      const data = JSON.parse(this.$route.query.data)
      this.money = data.money
      this.startTime = data.startTime
      this.endTime = data.endTime
      this.interestType = data.interestType
      setTimeout(() => {
        this.actualDays = data.actualDays
      })
      if (data.interestType === 'LPR') {
        this.basisPoint = data.basisPoint
        this.value = data.value
        this.LPRTime = data.LPRTime
        this.LPRType = data.LPRType
      } else if (data.interestType === '银行同期贷款利率') {
        this.LPRType = data.LPRType
      } else {
        this.multiple = data.multiple
        this.percentage = data.percentage
        this.switchValue = data.switchValue
      }
      this.isShare = true
      this.getResult()
    } else {
      const endY = new Date().getFullYear()
      const startY = endY - 1
      let M = new Date().getMonth() + 1
      M = M >= 10 ? M : '0' + M
      let D = new Date().getDate()
      D = D >= 10 ? D : '0' + D
      this.startTime = startY + '-' + M + '-' + D
      this.endTime = endY + '-' + M + '-' + D
      this.$watch('startTime', function () {
        this.getResult()
      })
      this.$watch('endTime', function () {
        this.getResult()
      })
      this.isShare = false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.fontSizeComp.fontSize = 20
      this.$refs.fontSizeComp.setFontSize()
    })
  },
  watch: {
    money(value) {
      if ((value + '').indexOf('.') === value.length - 1) {
        return
      }
      this.money = (value + '').replace(/^(-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3')
      this.getResult()
    },
    startTime(value) {
      const dateTime = new Date(this.endTime).getTime() - new Date(this.startTime).getTime()
      const day = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
      if (value) {
        if (this.endTime) {
          this.day = day
        } else {
          this.day = 0
        }
        this.options3[1] = {
          label: `取${value}的lpr`,
          value: '开始时间',
        }
      } else {
        this.day = 0
        this.LPRTime = '按每月lpr分段计算'
      }
      this.getResult()
    },
    endTime(value) {
      const dateTime = new Date(this.endTime).getTime() - new Date(this.startTime).getTime()
      const day = parseInt(dateTime / 1000 / 60 / 60 / 24) + 1
      if (value) {
        if (this.startTime) {
          this.day = day
        } else {
          this.day = 0
        }
        this.options3[2] = {
          label: `取${value}的lpr`,
          value: '结束时间',
        }
      } else {
        this.day = 0
        this.LPRTime = '按每月lpr分段计算'
      }
      this.getResult()
    },
    interestType(value) {
      if (value === '月利率' || value === '年利率') {
        this.actualDays = 365
      } else if (value === '银行同期贷款利率' || value === 'LPR') {
        this.actualDays = 360
      }
      this.getResult()
    },
    show(val) {
      if (val === false) {
        if (this.actualDays === '' || this.actualDays === 0 || this.actualDays === '0') {
          if (this.interestType === '月利率' || this.interestType === '年利率') {
            this.actualDays = 365
          } else {
            this.actualDays = 360
          }
        }
      }
    },
    actualDays(value) {
      if (value !== '' && value !== 0 && value !== '0') {
        this.getResult()
      }
    },
    percentage(value) {
      this.getResult()
    },
    value(value) {
      this.getResult()
    },
    basisPoint(value) {
      this.getResult()
    },
    multiple(value) {
      this.getResult()
    },
    switchValue(value) {
      this.getResult()
    },
    LPRTime(value) {
      this.getResult()
    },
    LPRType(value) {
      this.getResult()
    },
  },
}
</script>
<style lang="stylus">
.van-number-keyboard-input-select
  background rgba(25,137,250,0.3)
.tools-interest
  position relative
  & .box
    box-sizing border-box
    width 100%
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid #CCC
    & .van-cell
      padding 10px 0px
    & .van-radio-group
      display flex
      align-items center
    & .box-left
      & .input-box
        width 60px
        display flex
        flex-direction row
        align-items center
      & .van-switch
        font-size 16px
    & .box-right
      display flex
      flex-direction row
      align-items center
      justify-content flex-end
      flex-wrap wrap
      & .input-box
        width 60px
        display flex
        flex-direction row
        align-items center
      & .box-right-time
        flex 1
        text-align center
        height 46px
        line-height 46px
      & .other-item
        padding 5px 10px
        margin-top 5px
        margin-left 10px
        box-sizing border-box
        background rgba(0,0,0,0.5)
        color #FFF
  & .btn
    margin-top 20px
    display flex
    align-items center
    justify-content center
  & .interest-result-list
    padding 0 20px
    box-sizing border-box
    color red
    & > div
      padding 5px 0
      box-sizing border-box
      display flex
      flex-direction row
      align-items center
      justify-content space-between
  & .interest-result
    box-sizing border-box
    padding 0 20px
    margin-top 20px
    display flex
    flex-direction row
    align-items center
    color red
    justify-content space-between
    & > span
      cursor pointer
      color #0079FE
  & .interest-line
    width 100%
    height 1px
    background #cccccc
    margin 10px 0
  & .interest-table-title
    width 100%
    display flex
    justify-content space-between
    text-align center
    & > div
      flex 2
</style>
