/**
 * LPR基础数据
 * @startTime 开始时间
 * @endTime 结束时间
 * @values 利息 [0-6月LPR,6月-1年LPR,1-3年LPR,3-5年LPR,5年LPR,5年以上LPR]
 */

export default [
  {
    startTime: '1991-04-21',
    endTime: '1993-05-14',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [8.1, 8.64, 9.0, 9.54, 9.54, 9.72],
  },
  {
    startTime: '1993-05-15',
    endTime: '1993-07-10',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [8.82, 9.36, 10.8, 12.06, 12.06, 12.24],
  },
  {
    startTime: '1993-07-11',
    endTime: '1994-12-30',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [9.0, 10.98, 12.24, 13.86, 13.86, 14.04],
  },
  {
    startTime: '1995-01-01',
    endTime: '1995-06-30',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [9.0, 10.98, 12.94, 14.58, 14.58, 14.76],
  },
  {
    startTime: '1995-07-01',
    endTime: '1996-04-30',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [10.08, 12.06, 13.5, 15.12, 15.12, 15.3],
  },
  {
    startTime: '1996-05-01',
    endTime: '1996-08-22',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [9.72, 10.98, 13.14, 14.94, 14.94, 15.12],
  },
  {
    startTime: '1996-08-23',
    endTime: '1997-10-22',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [9.18, 10.08, 10.98, 11.7, 11.7, 12.42],
  },
  {
    startTime: '1997-10-23',
    endTime: '1998-03-24',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [7.65, 8.64, 9.36, 9.9, 9.9, 10.53],
  },
  {
    startTime: '1998-03-25',
    endTime: '1998-06-30',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [7.02, 7.92, 9.0, 9.72, 9.72, 10.35],
  },
  {
    startTime: '1998-07-01',
    endTime: '1998-12-06',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.57, 6.93, 7.11, 7.65, 7.65, 8.01],
  },
  {
    startTime: '1998-12-07',
    endTime: '1999-06-09',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.12, 6.39, 6.66, 7.2, 7.2, 7.56],
  },
  {
    startTime: '1999-06-10',
    endTime: '2002-02-20',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.58, 5.85, 5.94, 6.03, 6.03, 6.21],
  },
  {
    startTime: '2002-02-21',
    endTime: '2004-10-28',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.04, 5.31, 5.49, 5.58, 5.58, 5.76],
  },
  {
    startTime: '2004-10-29',
    endTime: '2006-04-27',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.22, 5.58, 5.76, 5.85, 5.85, 6.12],
  },
  {
    startTime: '2006-04-28',
    endTime: '2006-08-18',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.4, 5.85, 6.03, 6.12, 6.12, 6.39],
  },
  {
    startTime: '2006-08-19',
    endTime: '2007-03-17',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.58, 6.12, 6.3, 6.48, 6.48, 6.84],
  },
  {
    startTime: '2007-03-18',
    endTime: '2007-05-18',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.67, 6.39, 6.57, 6.75, 6.75, 7.11],
  },
  {
    startTime: '2007-05-19',
    endTime: '2007-07-20',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.85, 6.57, 6.75, 6.93, 6.93, 7.2],
  },
  {
    startTime: '2007-07-21',
    endTime: '2007-08-21',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.03, 6.84, 7.02, 7.2, 7.2, 7.38],
  },
  {
    startTime: '2007-08-22',
    endTime: '2007-09-14',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.21, 7.02, 7.2, 7.38, 7.38, 7.56],
  },
  {
    startTime: '2007-09-15',
    endTime: '2007-12-20',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.48, 7.29, 7.47, 7.65, 7.65, 7.83],
  },
  {
    startTime: '2007-12-21',
    endTime: '2008-09-15',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.57, 7.47, 7.56, 7.74, 7.74, 7.83],
  },
  {
    startTime: '2008-09-16',
    endTime: '2008-10-08',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.21, 7.2, 7.29, 7.56, 7.56, 7.74],
  },
  {
    startTime: '2008-10-09',
    endTime: '2008-10-29',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.12, 6.93, 7.02, 7.29, 7.29, 7.47],
  },
  {
    startTime: '2008-10-30',
    endTime: '2008-11-26',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.03, 6.66, 6.75, 7.02, 7.02, 7.2],
  },
  {
    startTime: '2008-11-27',
    endTime: '2008-12-22',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.04, 5.58, 5.67, 5.94, 5.94, 6.12],
  },
  {
    startTime: '2008-12-23',
    endTime: '2010-10-19',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [4.86, 5.31, 5.4, 5.76, 5.76, 5.94],
  },
  {
    startTime: '2010-10-19',
    endTime: '2010-12-25',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.1, 5.56, 5.6, 5.96, 5.96, 6.14],
  },
  {
    startTime: '2010-12-26',
    endTime: '2011-02-08',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.35, 5.81, 5.85, 6.22, 6.22, 6.4],
  },
  {
    startTime: '2011-02-09',
    endTime: '2011-04-05',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.6, 6.06, 6.1, 6.45, 6.45, 6.6],
  },
  {
    startTime: '2011-04-06',
    endTime: '2011-07-06',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.85, 6.31, 6.4, 6.65, 6.65, 6.8],
  },
  {
    startTime: '2011-07-07',
    endTime: '2012-06-07',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [6.1, 6.56, 6.65, 6.9, 6.9, 7.05],
  },
  {
    startTime: '2012-06-08',
    endTime: '2012-07-05',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.85, 6.31, 6.4, 6.65, 6.65, 6.8],
  },
  {
    startTime: '2012-07-06',
    endTime: '2014-11-21',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.6, 6.0, 6.15, 6.4, 6.4, 6.55],
  },
  {
    startTime: '2014-11-22',
    endTime: '2015-02-28',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.6, 5.6, 6.0, 6.0, 6.0, 6.15],
  },
  {
    startTime: '2015-03-01',
    endTime: '2015-05-10',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.35, 5.35, 5.75, 5.75, 5.75, 5.9],
  },
  {
    startTime: '2015-05-11',
    endTime: '2015-06-27',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [5.1, 5.1, 5.5, 5.5, 5.5, 5.65],
  },
  {
    startTime: '2015-06-28',
    endTime: '2015-08-25',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [4.85, 4.85, 5.25, 5.25, 5.25, 5.4],
  },
  {
    startTime: '2015-08-26',
    endTime: '2015-10-23',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [4.6, 4.6, 5.0, 5.0, 5.0, 5.15],
  },
  {
    startTime: '2015-10-24',
    endTime: '2019-08-19',
    types: ['六个月以内（含六个月）', '六个月至一年（含一年）', '一至三年（含三年）', '三至五年（含五年）', '三至五年（含五年）', '五年以上'],
    values: [4.35, 4.35, 4.75, 4.75, 4.75, 4.9],
  },
  {
    startTime: '2019-08-20',
    endTime: '2019-09-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [4.25, 4.25, 4.25, 4.25, 4.8, 4.8],
  },
  {
    startTime: '2019-09-20',
    endTime: '2019-10-20',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [4.2, 4.2, 4.2, 4.2, 4.8, 4.8],
  },
  {
    startTime: '2019-10-21',
    endTime: '2019-11-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [4.2, 4.2, 4.2, 4.2, 4.8, 4.8],
  },
  {
    startTime: '2019-11-20',
    endTime: '2019-12-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [4.15, 4.15, 4.15, 4.15, 4.8, 4.8],
  },
  {
    startTime: '2019-12-20',
    endTime: '2020-01-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [4.15, 4.15, 4.15, 4.15, 4.8, 4.8],
  },
  {
    startTime: '2020-01-20',
    endTime: '2020-02-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [4.15, 4.15, 4.15, 4.15, 4.8, 4.8],
  },
  {
    startTime: '2020-02-20',
    endTime: '2020-03-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [4.05, 4.05, 4.05, 4.05, 4.75, 4.75],
  },
  {
    startTime: '2020-03-20',
    endTime: '2020-04-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [4.05, 4.05, 4.05, 4.05, 4.75, 4.75],
  },
  {
    startTime: '2020-04-20',
    endTime: '2020-05-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.85, 3.85, 3.85, 3.85, 4.65, 4.65],
  },
  {
    startTime: '2020-05-20',
    endTime: '2020-06-21',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.85, 3.85, 3.85, 3.85, 4.65, 4.65],
  },
  {
    startTime: '2020-06-22',
    endTime: '2021-12-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.85, 3.85, 3.85, 3.85, 4.65, 4.65],
  },

  {
    startTime: '2021-12-20',
    endTime: '2022-01-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.8, 3.8, 3.8, 3.8, 4.65, 4.65],
  },

  {
    startTime: '2022-01-20',
    endTime: '2022-03-20',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.7, 3.7, 3.7, 3.7, 4.6, 4.6],
  },

  {
    startTime: '2022-03-21',
    endTime: '2022-05-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.7, 3.7, 3.7, 3.7, 4.6, 4.6],
  },
  {
    startTime: '2022-05-20',
    endTime: '2022-08-21',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.7, 3.7, 3.7, 3.7, 4.45, 4.45],
  },
  {
    startTime: '2022-08-22',
    endTime: '2023-06-19',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.65, 3.65, 3.65, 3.65, 4.3, 4.3],
  },
  {
    startTime: '2023-06-20',
    endTime: '2023-08-20',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.55, 3.55, 3.55, 3.55, 4.2, 4.2],
  },
  {
    startTime: '2023-08-21',
    endTime: '',
    types: ['1年期LPR', '1年期LPR', '1年期LPR', '1年期LPR', '5年期LPR', '5年期LPR'],
    values: [3.45, 3.45, 3.45, 3.45, 4.2, 4.2],
  },
]
