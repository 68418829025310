var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "利息计算", fixed: "" },
          slot: "header",
        },
        [
          !_vm.isShare
            ? _c(
                "fb-button",
                {
                  attrs: {
                    slot: "left",
                    icon: "back",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.backToNative()
                    },
                  },
                  slot: "left",
                },
                [_vm._v(" 返回 ")]
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "tool-head",
                {
                  attrs: { type: "interest" },
                  on: { clickItem: _vm.clickItem },
                },
                [
                  _c("font-size-comp", {
                    ref: "fontSizeComp",
                    on: { setFontSize: _vm.setFontSize },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tools-interest",
          staticStyle: { "font-size": "14px !important" },
          attrs: { id: "tools" },
        },
        [
          _c(
            "div",
            { staticClass: "box", staticStyle: { padding: "0px 20px" } },
            [
              _c("div", { staticClass: "box-left" }, [
                _vm._v(" 金额"),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.money,
                        expression: "money",
                      },
                    ],
                    staticStyle: { "margin-left": "5px" },
                  },
                  [_vm._v(_vm._s(_vm.yuanToWan(_vm.money)))]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "box-right",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _c("van-field", {
                    class:
                      _vm.keyboard === "money" && _vm.show
                        ? "van-number-keyboard-input-select"
                        : "",
                    staticStyle: { flex: "1" },
                    attrs: {
                      "input-align": "right",
                      placeholder: "请输入金额",
                      readonly: "",
                      clickable: "",
                    },
                    on: {
                      touchstart: function ($event) {
                        $event.stopPropagation()
                        _vm.basisPointKeyShow = false
                        _vm.show = true
                        _vm.keyboard = "money"
                      },
                    },
                    model: {
                      value: _vm.money,
                      callback: function ($$v) {
                        _vm.money = $$v
                      },
                      expression: "money",
                    },
                  }),
                  _c("van-icon", {
                    attrs: { name: "close" },
                    on: {
                      click: function ($event) {
                        _vm.money = ""
                        _vm.result = ""
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "box", staticStyle: { padding: "0px 20px" } },
            [
              _c("div", { staticClass: "box-left" }, [_vm._v("日期")]),
              _c(
                "div",
                { staticClass: "box-right", staticStyle: { flex: "1" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box-right-time",
                      on: {
                        click: function ($event) {
                          return _vm.showChangeTime("startTimeShow")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.startTime,
                              expression: "startTime",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.startTime))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.startTime,
                              expression: "!startTime",
                            },
                          ],
                          staticStyle: { color: "#ccc" },
                        },
                        [_vm._v("选择开始时间")]
                      ),
                    ]
                  ),
                  _c("div", [_vm._v("至")]),
                  _c(
                    "div",
                    {
                      staticClass: "box-right-time",
                      on: {
                        click: function ($event) {
                          return _vm.showChangeTime("endTimeShow")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.endTime,
                              expression: "endTime",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.endTime))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.endTime,
                              expression: "!endTime",
                            },
                          ],
                          staticStyle: { color: "#ccc" },
                        },
                        [_vm._v("选择结束时间")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { width: "80px", "text-align": "right" } },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.day > 0,
                              expression: "day > 0",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.day) + "天")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "box", staticStyle: { padding: "0px 20px" } },
            [
              _c(
                "div",
                {
                  staticClass: "box-left",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    flex: "1",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.typeShow = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.typeFmt(_vm.interestType, _vm.options)) +
                          " "
                      ),
                    ]
                  ),
                  _c("van-icon", { attrs: { name: "arrow-down" } }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.interestType !== "LPR" &&
                            _vm.interestType !== "银行同期贷款利率",
                          expression:
                            "interestType !== 'LPR' && interestType !== '银行同期贷款利率'",
                        },
                      ],
                      staticClass: "input-box",
                    },
                    [
                      _c("van-field", {
                        class:
                          _vm.keyboard === "percentage" && _vm.show
                            ? "van-number-keyboard-input-select"
                            : "",
                        staticStyle: { flex: "1" },
                        attrs: {
                          "input-align": "right",
                          placeholder: "请输入",
                          readonly: "",
                          clickable: "",
                        },
                        on: {
                          touchstart: function ($event) {
                            $event.stopPropagation()
                            _vm.basisPointKeyShow = false
                            _vm.show = true
                            _vm.keyboard = "percentage"
                          },
                        },
                        model: {
                          value: _vm.percentage,
                          callback: function ($$v) {
                            _vm.percentage = $$v
                          },
                          expression: "percentage",
                        },
                      }),
                      _c("van-icon", {
                        attrs: { name: "close" },
                        on: {
                          click: function ($event) {
                            _vm.percentage = ""
                            _vm.result = ""
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.interestType !== "LPR" &&
                            _vm.interestType !== "银行同期贷款利率",
                          expression:
                            "interestType !== 'LPR' && interestType !== '银行同期贷款利率'",
                        },
                      ],
                    },
                    [_vm._v("%")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.interestType !== "日利率",
                          expression: "interestType !== '日利率'",
                        },
                      ],
                      staticClass: "input-box",
                    },
                    [
                      _c("van-field", {
                        class:
                          _vm.keyboard === "actualDays" && _vm.show
                            ? "van-number-keyboard-input-select"
                            : "",
                        attrs: {
                          "input-align": "right",
                          placeholder: "请输入",
                          readonly: "",
                          clickable: "",
                        },
                        on: {
                          touchstart: function ($event) {
                            $event.stopPropagation()
                            _vm.basisPointKeyShow = false
                            _vm.show = true
                            _vm.keyboard = "actualDays"
                          },
                        },
                        model: {
                          value: _vm.actualDays,
                          callback: function ($$v) {
                            _vm.actualDays = $$v
                          },
                          expression: "actualDays",
                        },
                      }),
                      _c("van-icon", {
                        attrs: { name: "close" },
                        on: {
                          click: function ($event) {
                            _vm.actualDays = ""
                            _vm.result = ""
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.interestType !== "日利率",
                          expression: "interestType !== '日利率'",
                        },
                      ],
                    },
                    [_vm._v("天")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.interestType === "LPR",
                      expression: "interestType === 'LPR'",
                    },
                  ],
                  staticClass: "box-right",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.basisPointShow = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.typeFmt(_vm.value, _vm.options2)) + " "
                      ),
                    ]
                  ),
                  _c("van-icon", { attrs: { name: "arrow-down" } }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.value !== "倍率",
                          expression: "value !== '倍率'",
                        },
                      ],
                      staticClass: "input-box",
                    },
                    [
                      _c("van-field", {
                        class:
                          _vm.keyboard === "basisPoint" && _vm.basisPointKeyShow
                            ? "van-number-keyboard-input-select"
                            : "van-number1111",
                        attrs: {
                          "input-align": "right",
                          placeholder: "请输入",
                          readonly: "",
                          clickable: "",
                        },
                        on: {
                          touchstart: function ($event) {
                            $event.stopPropagation()
                            _vm.keyboard = "basisPoint"
                            _vm.show = false
                            _vm.basisPointKeyShow = true
                          },
                        },
                        model: {
                          value: _vm.basisPoint,
                          callback: function ($$v) {
                            _vm.basisPoint = $$v
                          },
                          expression: "basisPoint",
                        },
                      }),
                      _c("van-icon", {
                        attrs: { name: "close" },
                        on: {
                          click: function ($event) {
                            _vm.basisPoint = ""
                            _vm.result = ""
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.interestType !== "LPR" ||
                        (_vm.interestType === "LPR" && _vm.value === "倍率"),
                      expression:
                        "interestType !== 'LPR' || (interestType === 'LPR' && value === '倍率')",
                    },
                  ],
                  staticClass: "box-right",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-box" },
                    [
                      _c("van-field", {
                        class:
                          _vm.keyboard === "multiple" && _vm.show
                            ? "van-number-keyboard-input-select"
                            : "",
                        attrs: {
                          "input-align": "right",
                          placeholder: "请输入",
                          readonly: "",
                          clickable: "",
                        },
                        on: {
                          touchstart: function ($event) {
                            $event.stopPropagation()
                            _vm.basisPointKeyShow = false
                            _vm.show = true
                            _vm.keyboard = "multiple"
                          },
                        },
                        model: {
                          value: _vm.multiple,
                          callback: function ($$v) {
                            _vm.multiple = $$v
                          },
                          expression: "multiple",
                        },
                      }),
                      _c("van-icon", {
                        attrs: { name: "close" },
                        on: {
                          click: function ($event) {
                            _vm.multiple = ""
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("倍")]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.interestType !== "LPR" &&
                    _vm.interestType !== "银行同期贷款利率",
                  expression:
                    "interestType !== 'LPR' && interestType !== '银行同期贷款利率'",
                },
              ],
              staticClass: "box",
              staticStyle: { padding: "15px 20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-left",
                  staticStyle: { display: "flex", "align-item": "center" },
                },
                [
                  _c("span", [_vm._v("无复利")]),
                  _c("van-switch", {
                    model: {
                      value: _vm.switchValue,
                      callback: function ($$v) {
                        _vm.switchValue = $$v
                      },
                      expression: "switchValue",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "box-right" }, [
                _c("div", { staticClass: "repayment-during" }),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    (_vm.interestType === "LPR" &&
                      _vm.startTime &&
                      _vm.endTime) ||
                    (_vm.interestType === "银行同期贷款利率" &&
                      _vm.startTime &&
                      _vm.endTime),
                  expression:
                    "(interestType === 'LPR' && startTime && endTime) || (interestType === '银行同期贷款利率' && startTime && endTime)",
                },
              ],
              staticClass: "box",
              staticStyle: { padding: "15px 20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-left",
                  staticStyle: { display: "flex", "align-item": "center" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.interestType === "LPR",
                          expression: "interestType === 'LPR'",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          _vm.LPRTimeShow = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.typeFmt(_vm.LPRTime, _vm.options3)) +
                          " "
                      ),
                    ]
                  ),
                  _c("van-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.interestType === "LPR",
                        expression: "interestType === 'LPR'",
                      },
                    ],
                    attrs: { name: "arrow-down" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box-right" },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.LPRTypeShow = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.typeFmt(_vm.LPRType, _vm.options4)) +
                          " "
                      ),
                    ]
                  ),
                  _c("van-icon", { attrs: { name: "arrow-down" } }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.result,
                  expression: "result",
                },
              ],
              staticClass: "interest-result",
            },
            [
              _c("div", [_vm._v("利息：" + _vm._s(_vm.interest) + "元")]),
              _c("div", [_vm._v("本金利息：" + _vm._s(_vm.result) + "元")]),
              _c("i", {
                staticClass: "iconfont icon-fuzhi",
                staticStyle: {
                  color: "#000000",
                  "margin-left": "20px",
                  cursor: "pointer",
                },
                on: { click: _vm.copy },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.result,
                  expression: "result",
                },
              ],
              staticStyle: { color: "red", padding: "0 20px" },
            },
            [_vm._v(" " + _vm._s(_vm.overtext) + " ")]
          ),
          _c("div", { staticClass: "interest-result" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.result,
                    expression: "result",
                  },
                ],
                on: {
                  click: function ($event) {
                    _vm.isShowInfo = !_vm.isShowInfo
                  },
                },
              },
              [_vm._v("详情")]
            ),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.result,
                  expression: "!result",
                },
              ],
            }),
            _c("span", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.result,
                      expression: "result",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.copyAll },
                },
                [_vm._v("复制")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.result && !_vm.iphone,
                      expression: "result && !iphone",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.getWORD },
                },
                [_vm._v("下载")]
              ),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.share },
                },
                [_vm._v("分享")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "interest-line" }),
          _vm.type === 0
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowInfo && _vm.result,
                      expression: "isShowInfo && result",
                    },
                  ],
                  staticStyle: { padding: "0 20px" },
                },
                [
                  _c(
                    "p",
                    {
                      style: `font-size:${_vm.fontSize}px !important;color:#CCC`,
                    },
                    [_vm._v(" " + _vm._s(_vm.text3) + " ")]
                  ),
                  _c("p", { style: `font-size:${_vm.fontSize}px !important` }, [
                    _vm._v(_vm._s(_vm.text1)),
                  ]),
                  _c("p", { style: `font-size:${_vm.fontSize}px !important` }, [
                    _vm._v("总计"),
                  ]),
                  _c(
                    "p",
                    {
                      style: `font-size:${_vm.fontSize}px !important;color:#CCC`,
                    },
                    [_vm._v("本息=本金+利息")]
                  ),
                  _c("p", { style: `font-size:${_vm.fontSize}px !important` }, [
                    _vm._v(_vm._s(_vm.text2)),
                  ]),
                  _c("p", { style: `font-size:${_vm.fontSize}px !important` }, [
                    _vm._v(_vm._s(_vm.overtext)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.type === 1
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowInfo && _vm.result,
                      expression: "isShowInfo && result",
                    },
                  ],
                  staticStyle: { padding: "0 20px" },
                },
                [
                  _c(
                    "div",
                    {
                      style: `font-size:${_vm.fontSize}px !important;color:#CCC`,
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.LPR.interestText) },
                      }),
                    ]
                  ),
                  _vm.LPR.LPRType === "默认"
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "interest-table-title" }, [
                            _c("div", {
                              staticStyle: { flex: "1" },
                              style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: { flex: "4" },
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("起止时间")]
                            ),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("天数")]
                            ),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("类型")]
                            ),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("利率(%)")]
                            ),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("利息(元)")]
                            ),
                          ]),
                          _vm._l(_vm.LPR.list, function (item, i) {
                            return _c("div", { key: i }, [
                              _c(
                                "div",
                                { staticClass: "interest-table-title" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { flex: "1" },
                                      style: `font-size:${
                                        _vm.fontSize - 1
                                      }px !important`,
                                    },
                                    [_vm._v(" " + _vm._s(i + 1) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { flex: "4" },
                                      style: `font-size:${
                                        _vm.fontSize - 1
                                      }px !important`,
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.startTime) +
                                          "-" +
                                          _vm._s(item.endTime)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      style: `font-size:${
                                        _vm.fontSize - 1
                                      }px !important`,
                                    },
                                    [_vm._v(" " + _vm._s(item.day) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      style: `font-size:${
                                        _vm.fontSize - 1
                                      }px !important`,
                                    },
                                    [_vm._v(" " + _vm._s(item.type) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      style: `font-size:${
                                        _vm.fontSize - 1
                                      }px !important`,
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.percentage) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      style: `font-size:${
                                        _vm.fontSize - 1
                                      }px !important`,
                                    },
                                    [_vm._v(" " + _vm._s(item.interest) + " ")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "flex-start",
                                    margin: "5px 10px",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      style: `font-size:${
                                        _vm.fontSize - 1
                                      }px !important`,
                                    },
                                    [
                                      _vm._v(
                                        "本金" +
                                          _vm._s(_vm.money) +
                                          "元*" +
                                          _vm._s(item.percentage) +
                                          "%lpr*" +
                                          _vm._s(item.day) +
                                          "天/" +
                                          _vm._s(_vm.actualDays) +
                                          "天=利息" +
                                          _vm._s(item.interest) +
                                          "元"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                        ],
                        2
                      )
                    : _c(
                        "div",
                        [
                          _c("div", { staticClass: "interest-table-title" }, [
                            _c("div", {
                              staticStyle: { flex: "1" },
                              style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                            }),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("月份")]
                            ),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("还款额")]
                            ),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("利息")]
                            ),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("还款本金")]
                            ),
                            _c(
                              "div",
                              {
                                style: `font-size:${_vm.fontSize}px !important;font-weight: bold;`,
                              },
                              [_vm._v("剩余本金")]
                            ),
                          ]),
                          _vm._l(_vm.LPR.list, function (item, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "interest-table-title" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { flex: "1" },
                                    style: `font-size:${
                                      _vm.fontSize - 1
                                    }px !important`,
                                  },
                                  [_vm._v(" " + _vm._s(i + 1) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    style: `font-size:${
                                      _vm.fontSize - 1
                                    }px !important`,
                                  },
                                  [_vm._v(" " + _vm._s(item.time) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    style: `font-size:${
                                      _vm.fontSize - 1
                                    }px !important`,
                                  },
                                  [_vm._v(" " + _vm._s(item.repayment) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    style: `font-size:${
                                      _vm.fontSize - 1
                                    }px !important`,
                                  },
                                  [_vm._v(" " + _vm._s(item.interest) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    style: `font-size:${
                                      _vm.fontSize - 1
                                    }px !important`,
                                  },
                                  [_vm._v(" " + _vm._s(item.money) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    style: `font-size:${
                                      _vm.fontSize - 1
                                    }px !important`,
                                  },
                                  [_vm._v(" " + _vm._s(item.surplus) + " ")]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                  _c(
                    "p",
                    { style: `font-size:${_vm.fontSize}px !important;` },
                    [_vm._v("总计")]
                  ),
                  _c(
                    "p",
                    {
                      style: `font-size:${_vm.fontSize}px !important;color:#CCC`,
                    },
                    [_vm._v("本息=本金+利息")]
                  ),
                  _c("p", { style: `font-size:${_vm.fontSize}px !important` }, [
                    _vm._v(" " + _vm._s(_vm.LPR.resultText) + " "),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.startTimeShow,
            callback: function ($$v) {
              _vm.startTimeShow = $$v
            },
            expression: "startTimeShow",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择开始时间",
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.startTimeConfirm,
              cancel: function ($event) {
                _vm.startTimeShow = false
              },
            },
            model: {
              value: _vm.startTimeDate,
              callback: function ($$v) {
                _vm.startTimeDate = $$v
              },
              expression: "startTimeDate",
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.endTimeShow,
            callback: function ($$v) {
              _vm.endTimeShow = $$v
            },
            expression: "endTimeShow",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择结束时间",
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.endTimeConfirm,
              cancel: function ($event) {
                _vm.endTimeShow = false
              },
            },
            model: {
              value: _vm.endTimeDate,
              callback: function ($$v) {
                _vm.endTimeDate = $$v
              },
              expression: "endTimeDate",
            },
          }),
        ],
        1
      ),
      _c("van-number-keyboard", {
        attrs: {
          show: _vm.show,
          theme: "custom",
          "extra-key": ".",
          "close-button-text": "完成",
        },
        on: {
          blur: function ($event) {
            _vm.show = false
          },
          input: _vm.onInput,
          delete: _vm.onDelete,
        },
      }),
      _c("van-number-keyboard", {
        attrs: {
          show: _vm.basisPointKeyShow,
          theme: "custom",
          "extra-key": ["-", "."],
          "close-button-text": "完成",
        },
        on: {
          blur: function ($event) {
            _vm.basisPointKeyShow = false
          },
          input: _vm.onInput,
          delete: _vm.onDelete,
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.typeShow,
            callback: function ($$v) {
              _vm.typeShow = $$v
            },
            expression: "typeShow",
          },
        },
        [
          _c("cell-radio-group", {
            attrs: { radio: _vm.interestType, options: _vm.options },
            on: {
              "update:radio": function ($event) {
                _vm.interestType = $event
              },
              select: _vm.typeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.basisPointShow,
            callback: function ($$v) {
              _vm.basisPointShow = $$v
            },
            expression: "basisPointShow",
          },
        },
        [
          _c("cell-radio-group", {
            attrs: { radio: _vm.value, options: _vm.options2 },
            on: {
              "update:radio": function ($event) {
                _vm.value = $event
              },
              select: _vm.basisPointChange,
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.LPRTimeShow,
            callback: function ($$v) {
              _vm.LPRTimeShow = $$v
            },
            expression: "LPRTimeShow",
          },
        },
        [
          _c("cell-radio-group", {
            attrs: { radio: _vm.LPRTime, options: _vm.options3 },
            on: {
              "update:radio": function ($event) {
                _vm.LPRTime = $event
              },
              select: _vm.LPRTimeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.LPRTypeShow,
            callback: function ($$v) {
              _vm.LPRTypeShow = $$v
            },
            expression: "LPRTypeShow",
          },
        },
        [
          _c("cell-radio-group", {
            attrs: { radio: _vm.LPRType, options: _vm.options4 },
            on: {
              "update:radio": function ($event) {
                _vm.LPRType = $event
              },
              select: _vm.LPRTypeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }